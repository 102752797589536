import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import PostThumbnail from '../../components/PostThumbnail';
import PostTitle from '../../components/PostTitle';
import PostEntryMeta from '../../components/PostEntryMeta';

const RECENT_POSTS_QUERY = graphql`
query GetRecentPosts {
  wpgraphql {
    posts(first: 6, where: {orderRand: true, categoryName: "art-design"}) {
      nodes {
	    id
	    postId
	    title
	    date
	    excerpt
	    content
	    uri
	    link
	    featuredImage {
	    	node {
	    		sourceUrl(size: GRID_THUMB)
	    		title
	    	}
	    }
	    categories {
	      nodes {
	        name
	        slug
	        id
	      }
	    }
	    tags {
	      nodes {
	        slug
	        name
	        id
	      }
	    }
	    author {
	    	node {
	      		name
	    		slug
	    	}
	    }
      }
    }
  }
}
`

const ArtDesignBlock = ({ sectionTitle, catSlug }) => (
	<StaticQuery
	query={RECENT_POSTS_QUERY}
	render={ data => {
 		const { posts } = data.wpgraphql
 		return (
	    <section className="block-section block-posts-3col">
	      <div className="row-full">
	      <div className="container">
	      <div className="row justify-content-center lsco-block pt-5">
			<div className="col-12">
			<div className="block-header mb-4">
				<h3 className="block-title h6">
					<Link to={`/category/${catSlug}`}>{sectionTitle}</Link>
				</h3>
			</div>
			</div>{/* Block header */}

			{posts.nodes.length
				? posts.nodes.map(post => (
					
					<div className="columns col-sm-4" key={post.postId}>
					<article className="post type-post lsco-post">
						{post.featuredImage && <PostThumbnail post={post} location="block" width="760" height="480" />}
						<PostTitle
						location="block"
						post={post}
						titleClass="entry-title h5"
						/>
						<PostEntryMeta post={post} location="block" />
					</article>
					</div>

				))
			: null}

	      </div>{/* End of .lsco-block */}
	      </div>{/* End of .container */}
	      </div>{/* End of .row-full */}
	    </section>
	 	)
	}}
	/>
)

export default ArtDesignBlock