import React from "react"
import Layout from "../components/Layout"
import SEO from '../components/SEO';

import FeaturedBlock from "../components/blocks/FeaturedBlock"
import LatestBlock from "../components/blocks/LatestBlock"
import ArtDesignBlock from "../components/blocks/ArtDesignBlock"
import WallpapersBlock from "../components/blocks/WallpapersBlock"
import MockupsBlock from "../components/blocks/MockupsBlock"


export default () => (
  <Layout>
    
    <SEO title="Layerbag" />
    
    <FeaturedBlock />
    
    <LatestBlock
      sectionTitle="Latest"
      linkSlug="blog/page/2"
     />
    
    <ArtDesignBlock
    	sectionTitle="Art & Design"
    	catSlug="art-design"
     />

     <WallpapersBlock
      sectionTitle="Wallpapers"
      catSlug="wallpapers"
     />

     <MockupsBlock
      sectionTitle="Mockups"
      catSlug="psd-mockups"
     />


  </Layout>
)