//import React from "react"
import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import PostThumbnail from '../../components/PostThumbnail';
import PostTitle from '../../components/PostTitle';
import PostEntryMeta from '../../components/PostEntryMeta';

import SidebarLatest from "../../components/blocks/SidebarLatest"

//import { LazyLoadComponent } from 'react-lazy-load-image-component';
//import 'react-lazy-load-image-component/src/effects/blur.css';

const RECENT_POSTS_QUERY = graphql`
query GetTrendingPosts {
  wpgraphql {
    posts(where: {excludeFeatured: true}, first: 8) {
      nodes {
	    id
	    postId
	    title
	    date
	    excerpt
	    uri
	    link
	    featuredImage {
	    	node {
            	sourceUrl
	    		title
            }
	    }
	    categories {
	      nodes {
	        name
	        slug
	        id
	      }
	    }
	    tags {
	      nodes {
	        slug
	        name
	        id
	      }
	    }
	    author {
        	node {
        		name
        		slug
        	}
      	}	
      }
    }
  }
}
`

const LatestBlock = ({ sectionTitle, linkSlug }) => (

	<StaticQuery
	query={RECENT_POSTS_QUERY}
	render={ data => {
 		const { posts } = data.wpgraphql
 		return (
		<section className="block-section block-latest layout-sidebar">
			<div className="row">
			<div className="container">
			<div className="row lsco-block py-5">
			<div className="col-12">
			<div className="block-header mb-4">
				<h3 className="block-title h6">
					<Link to={`/${linkSlug}`}>{sectionTitle}</Link>
				</h3>
			</div>
			</div>{/* Block header */}

			<div id="primary" className="content-area">
				<main id="main" className="site-main">
					<div className="post-archive">
						<div className="archive-main archive-list">
							{posts.nodes.length
								? posts.nodes.map(post => (
								
								<article id={`post-${post.postId}`} className={`post post-${post.postId} type-post lsco-post post-list`} key={post.postId}>

									<div className="post-outer row align-items-center">
									
										<div className="col-sm-6 col-md-6">
										{post.featuredImage && <PostThumbnail post={post} location="block" width="760" height="480" />}
										</div>{/* End of .post-inner */}

										<div className="post-inner col-sm-6 col-md-6">
											<header className="entry-header">
												<PostTitle
												location="block"
												post={post}
												titleClass="entry-title h5"
												/>
												<PostEntryMeta post={post} location="block" />
											</header>
											<div className="post-excerpt" dangerouslySetInnerHTML={{ __html: post.excerpt }} />{/* End of .post-excerpt */}
										</div>{/* End of .post-inner */}
									
									</div>{/* End of .post-outer */}

								</article>

								))
							: null}

							<Link to={`/blog/page/2/`} className="text-dark text-capitalize">More posts &rarr;</Link>
						</div>{/* End of .archive-list */}
					</div>{/* End of .post-archive */}
				</main>{/* End of #main */}
			</div>{/* End of #primary */}

			<SidebarLatest/>

			</div>{/* End of .lsco-block */}
			</div>{/* End of .container */}
			</div>{/* End of .row-full */}
	    </section>
	 	)
	}}
	/>

)

export default LatestBlock