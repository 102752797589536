import React from "react"
import { Link } from "gatsby"


const OverlayMedia = ({ post, location, width, height }) => {
  const imgUrl = post.featuredImage.node.sourceUrl.substring(0, post.featuredImage.node.sourceUrl.indexOf('?'));
  const lowresImg = imgUrl+'?resize='+width/50+'%2C'+height/50+'&ssl=1';
  const highresImg = imgUrl+'?resize='+width+'%2C'+height+'&ssl=1';
  return (
  <div className="overlay-media">
    <img src={lowresImg} data-src={highresImg} className="lazyload blur-up" alt={post.title}  />
    <Link
      className="overlay-link"
      to={`${post.uri}`}
      aria-hidden="true"
    ></Link>
  </div>
  );
};

export default OverlayMedia
