import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import PostThumbnail from '../../components/PostThumbnail';

const RECENT_POSTS_QUERY = graphql`
query GetWallpapersPosts {
  wpgraphql {
    posts(first: 12, where: {orderRand: true, categoryName: "wallpapers"}) {
      nodes {
	    id
	    postId
	    title
	    date
	    excerpt
	    uri
	    link
	    featuredImage {
	    	node {
	    		sourceUrl(size: GRID_THUMB)
	      		title
	      	}
	    }
	    categories {
	      nodes {
	        name
	        slug
	        id
	      }
	    }
	    tags {
	      nodes {
	        slug
	        name
	        id
	      }
	    }
	    author {
	    	node {
	      		name
	    		slug
	    	}
	    }
      }
    }
  }
}
`

const WallpapersBlock = ({ sectionTitle, catSlug }) => (
	<StaticQuery
	query={RECENT_POSTS_QUERY}
	render={ data => {
 		const { posts } = data.wpgraphql
 		return (
	    <section className="block-section block-post-squares py-5">
	      <div className="row-full">
	      <div className="container">
	      <div className="row justify-content-center lsco-block">
			<div className="col-12">
			<div className="block-header mb-4">
				<h3 className="block-title h6">
					<Link to={`/category/${catSlug}`}>{sectionTitle}</Link>
				</h3>
			</div>
			</div>{/* End of .col-12 Block header */}
		  </div>{/* End of .row */}
		  </div>{/* End of .container */}
		  <div className="container-fluid">
		  <div className="row">
		  <div className="col-12">
		  <div className="block-inner clearfix">

			{posts.nodes.length
				? posts.nodes.map(post => (
					
					<div className="column" key={post.postId}>
					<article className="post type-post post-square">
						{post.featuredImage && <PostThumbnail post={post} location="block" width="760" height="480" />}
					</article>
					</div>

				))
			: null}

	      </div>{/* End of .block-inner clearfix */}
	      </div>{/* End of .col-12 */}
	      </div>{/* End of .row */}
	      </div>{/* End of .container-fluid */}
	      </div>{/* End of .row-full */}
	    </section>
	 	)
	}}
	/>
)

export default WallpapersBlock