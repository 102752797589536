import React from "react"
import { StaticQuery, graphql, Link, } from "gatsby"

import OverlayMedia from '../../components/OverlayMedia';
import PostTitle from '../../components/PostTitle';

const FeaturedBlock = () => (
  <StaticQuery
    query={graphql`
      {
        wpgraphql {
          posts(where: {tagSlugIn: "featured"}, first: 3) {
            nodes {
              id
              postId
              title
              date
              excerpt
              uri
              link
              featuredImage {
                node {
                  sourceUrl
                  title
                }
              }
              categories {
                nodes {
                  name
                  slug
                  id
                }
              }
              tags {
                nodes {
                  name
                  slug
                  id
                }
              }
              author {
                node {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `}
    render={ data => {
      const { posts } = data.wpgraphql
      return (
      <section className="block-section block-grids">
        <div className="row-full">
        <div className="container">
        <div className="row justify-content-center lsco-block pt-5">

        <div className="col-12">
        
        <div className="block-grid style-align-left">
        {posts.nodes.length
          ? posts.nodes.map(post => (
            
            <article className="post type-post lsco-post grid-item" key={post.postId}>
              <div className="post-outer overlay">
                {post.featuredImage && <OverlayMedia post={post} location="grid" width="1140" height="960" />}
                <div className="overlay-outer post-inner">
                  <div className="overlay-inner">
                    {post.categories.nodes.map(category => (
                      <div className="entry-meta meta-category" key={category.name}>
                      <Link
                        to={`/category/${category.slug}/`}
                        rel="category"
                        dangerouslySetInnerHTML={{ __html: category.name, }}
                      />
                      </div>
                    ))
                    .reduce((accu, elem) => {
                      return accu === null ? [elem] : [...accu, ", ", elem]
                    }, null)}
                    <PostTitle
                    location="block"
                    post={post}
                    titleClass="entry-title h5"
                    />
                  </div>
                </div>

              </div>{/* End of .post-outer */}
            </article>

          ))
        : null}
        </div>
        </div>

        </div>{/* End of .lsco-block */}
        </div>{/* End of .container */}
        </div>{/* End of .row-full */}
      </section>
      )
  }}
  />
)

export default FeaturedBlock
